import React, { useReducer } from "react";
import api from "../../network/Api";
import AppointmentsContext from "./appointmentsContext";
import AppointmentsReducer from "./appointmentsReducer";
import {
	GET_APPOINTMENTS,
	GET_APPOINTMENT,
	GET_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_APPOINTMENTS_CALENDAR_FAIL,
	GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL,
	GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_PAST_APPOINTMENTS_CALENDAR_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const AppointmentsState = props => {
	const initialState = {
		appointmentsCalendar: [],
		appointments: [],
		appointment: {},
		futureAppointments: [],
		pastAppointments: [],
		loading: true
	}

	const [ state, dispatch ] = useReducer(AppointmentsReducer, initialState);

	const getAppointments = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/appointments/v2/appointments`, config);

			dispatch({
				type: GET_APPOINTMENTS,
				payload: res.data.items
			});
		} catch (err) {
			dispatch({
				type: GET_APPOINTMENTS,
				payload: []
			});
		}
	};

	const getAppointment = async (appointmentIdentifier) => {
		setLoading();

		const res = await api.get(`/appointments/v2/appointments/${appointmentIdentifier}`);

		dispatch({
			type: GET_APPOINTMENT,
			payload: res.data
		});
	};

	const getAppointmentsCalendar = async (resources, start, end) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			let resourcesJoin = resources.join(',');
			const res = await api.get(`/appointments/v2/appointments/calendar?start=${start}&end=${end}&resources=${resourcesJoin}`, config);

			dispatch({
				type: GET_APPOINTMENTS_CALENDAR_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: GET_APPOINTMENTS_CALENDAR_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const getFutureAppointments = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/appointments/v2/appointments/active`, config);

			dispatch({
				type: GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS,
				payload: res.data.items
			});
		} catch (err) {
			dispatch({
				type: GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const getPastAppointments = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/appointments/v2/appointments/active`, config);

			dispatch({
				type: GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS,
				payload: res.data.items
			});
		} catch (err) {
			dispatch({
				type: GET_PAST_APPOINTMENTS_CALENDAR_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });


	return <AppointmentsContext.Provider
		value={{
			appointments: state.appointments,
			appointment: state.appointment,
			futureAppointments: state.futureAppointments,
			pastAppointments: state.pastAppointments,
			success: state.resetSuccess,
			error: state.resetError,
			loading: state.loading,
			getAppointments,
			getAppointment,
			getFutureAppointments,
			getPastAppointments,
			getAppointmentsCalendar,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</AppointmentsContext.Provider>
}

export default AppointmentsState;